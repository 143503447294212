import * as React from "react"
import Layout from "../components/layout"
import Boathouse from "../components/boathouse"
import { graphql } from "gatsby"

import "../styles/reset.css"
import "../styles/base.scss"
import "../styles/index.scss"

// markup
const IndexPage = ({ data }) => {
  const boathouses = data.allContentfulBoathouse.edges;

  return (
    <Layout home={true}>
      <title>Cambridge Boathouses</title>
      <main>
        <div className="boathouses">
        {boathouses.map((item) => {
          const boathouse = item.node;
          return (
            <Boathouse boathouse={boathouse} key={boathouse.id} />
          )
        })}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulBoathouse(sort: { fields: location___lon }) {
      edges {
        node {
          id
          name
          slug
          location {
            lat
            lon
          }
          primaryPhoto {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            gatsbyImageData(width: 400, placeholder: BLURRED)
          }
          rowingClubs {
            name
            boatCode
            id
          }
        }
      }
    }
  }
`

export default IndexPage
