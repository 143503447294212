import React from "react"
import HeaderTag from "./headerTag"

const year = new Date().getFullYear();

export default function Layout({ children, home }) {
    return (
        <div>
            <header>
                <HeaderTag home={home}>Cambridge Boathouses</HeaderTag>
            </header>
            {children}
            <footer>
                <small>&copy; {year} <a href="https://www.stephenkeable.co.uk" target="_blank" rel="noreferrer">Stephen Keable</a></small>
            </footer>
            <script src="https://cdn.usefathom.com/script.js" data-site="SKRRCDDC" defer></script>
        </div>
    )
}