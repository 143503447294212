import React from "react"

export default function HeaderTag({ children, home }) {
    if (home === true) {
        return (
            <h1>{children}</h1>
        )
    } else {
        return (
            <span>{children}</span>
        )
    }
}