import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Club from "./club"
import { Link } from "gatsby";

export default function Boathouse({ boathouse }) {
    const image = getImage(boathouse.primaryPhoto);
    let landscapeClass = '';
    if (boathouse.primaryPhoto.file.details.image.height < boathouse.primaryPhoto.file.details.image.width) {
        landscapeClass = 'land';
    }
    const rowingClubs = boathouse.rowingClubs || [];

    const clubsHeading = (clubs) => {
        if (clubs.length > 1) {
            return <h3>Clubs</h3>
        }
        if (clubs.length === 1) {
            return <h3>Club</h3>
        }
        return false
    };
    
    return (
        <article className={landscapeClass}>
            <GatsbyImage image={image} alt={boathouse.name} placeholder="blurred" layout="full-width" />
            <div className="text">
                <h2><Link to={ `/boathouses/${boathouse.slug}` }>{ boathouse.name }</Link></h2>
                { clubsHeading(rowingClubs) }
                { rowingClubs.map((club) => {
                    return (
                        <Club club={club} key={club.id} />
                    )
                })}
            </div>
        </article>
    )
}